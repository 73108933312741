import * as React from 'react'
import Logo from '../../images/oldLogo.svg'
import { Link } from 'gatsby'
const Privacy = () => {
  return <div className='px-7.5 py-7.5 overflow-hidden text-gotin bg-gotin text-center'>
    <div className='flex justify-center'>
      <img src={Logo} alt="gotin" className='w-45' />
    </div>
    <h2 className='text-center my-5 text-xlg font-semibold'>隐私政策</h2>
    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">最新更新日期：2023年4月24日</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">北京有⼤科技有限公司，主办或承办各类⼤型线上活动（含会议及会展）。北京有大科技有限公司（以下简称为“有大”或“我们”）高度重视对您的个人信息的保护。《隐私政策》（以下简称为“本政策”）按照中国相关的法律、法规、标准制订，旨在释明我们如何收集、处理和保护您的个人信息，以及您如何维护您的合法权益。</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">本政策将帮助您具体了解以下内容：

      一、 我们如何收集和使用您的个人信息

      二、 我们如何使用Cookie和同类技术

      三、 我们如何委托处理、共享、转让、公开披露您的个人信息

      四、 我们如何保护您的个人信息

      五、 您的权利

      六、 我们如何处理儿童的个人信息

      七、 本政策如何更新

      八、 如何联系我们</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
      【特别提⽰】本政策适⽤于我们提供的所有的服务。当您使⽤我们任何单项服务时，您同意接受本政策以及我们在该单项服务中设置的隐私信息类政策条款的保护。
    </p>
    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">本隐私政策适用于有大的官方网站<span className='text-gotinc'><Link to="/" >https://www.gotin.online</Link></span>以及有大承办的各类线上活动的网站、APP、微信公众号等（以下统称为“平台”），适用于我们从参加有大主办及承办的各类线上活动的参展商及参展商的员工、联系人或其他代表，以及平台访客（以下统称为“您”）处收集的信息。

      我们的平台中设有向第三方平台的跳转链接。我们向您特别说明，本政策不适用于与我们的平台、产品和/或服务集成的第三方应用程序或软件，或者第三方产品、服务或业务（以下统称为“第三方服务”）。第三方服务受其自有隐私政策约束，我们不对受第三方隐私政策约束的个人信息的收集、处理和保护行为承担责任。我们建议您在使用任何第三方服务之前仔细查看该等第三方的隐私政策。

      请您在使用我们的产品和/或服务前审慎阅读并确认您已经充分理解本政策内容，按照本政策的指引，做出您认为适当的选择。我们对本政策中涉及您的个人敏感信息，采用粗体斜体字标注以提示您注意。

      不同意本政策或其更新（我们将及时通知您此类更新）可能会影响您正常使用或继续正常使用我们的产品和/或服务。通过单击本政策的“接受”按钮，您明确同意我们可以按照本政策收集、使用、保存、共享、转让、公开披露以及保护您的个人信息。

      如果您对本政策或相关问题有任何疑问或顾虑，请通过“八、如何联系我们”中所提供的联系方式与我们联系。</p>

    <h4 className="font-semibold my-2.5">一、 我们如何收集和使用您的个人信息</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1 ">（一）我们收集您的个人信息的情形及种类</p>

    <h4 className="font-semibold my-2.5">1. 留资信息</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">
    本次活动的奖品由友邦提供，关于您的留资信息的隐私政策，请查阅
    <span className='text-gotinc'> <Link to="/" >http://www.aia.com.cn/zh-cn/tools/privacy-statement.html</Link></span>
    </p>

    <h4 className="font-semibold my-2.5">2. 账户注册</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">在注册账户过程中我们将向您收集必要的个人信息，包括：

      · 注册参展商企业账户时需要向我们提供的参展商联系人必要的个人信息，包括个人基本资料（姓名、手机号码、电子邮箱地址等）、个人身份信息（身份证号码等）、账号密码等；

      · 注册参展商员工或平台用户个人账户时需要向我们提供的必要的个人信息，包括个人基本资料（姓名、性别、固定电话号码、手机号码、电子邮箱地址等）、个人教育工作信息（所属部门、职位等）、账号密码等；</p>

    <h4 className="font-semibold my-2.5">3. 在线行为统计</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">为帮助参展商优化展品展示形式、直播效果，以及便于后续研究，我们可能会自动收集并保存参展商以及平台访客的行踪轨迹、网页浏览记录等个人信息，包括但不限于：

      · 网络身份标识信息（系统账号、IP地址、邮箱地址及与前述有关的密码、口令、口令保护答案等）；

      · 上网记录（用户的访问次数、访问页面、关注展商和展品、访问时间、访问时长、询盘情况等）；

      · 个人设备信息（设备名称、设备型号、设备Mac地址、唯一设备识别码、软件列表、操作系统和应用程序版本、语言设置等）；

      · 位置信息（精准定位信息等）。

      请您注意，您的搜索关键词信息无法单独识别您的身份，不属于您的个人信息，我们有权以任何的目的对其进行使用；只有当您的搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本政策对其进行处理与保护。</p>

    <h4 className="font-semibold my-2.5">4. 客户服务</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">当您联系我们的客服时，为了保障您的账户安全，我们需要您提供必要的个人信息以核验您的身份。为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信/通话记录及相关内容（账号信息、您为了证明相关事实提供的其他信息，或您留下的电话号码、电子邮箱地址等联系方式）。</p>

    <h4 className="font-semibold my-2.5">5. 其他附加服务</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">为便利采购商与参展商之间的沟通，企业采购商的员工、联系人或其他代表及个人采购商可在我们平台上通过即时通讯、留言等方式进行询盘。如您希望使用这一功能，根据有关实名制的管理要求，您可能需要向我们提供真实姓名、个人身份信息（身份证号码、护照号码等）等信息，以便我们进行实名认证。

      为改善和提高我们的服务，我们可能会通过问卷调查、意见征集或其他营销调查活动，向您了解您的意见或意向，如您选择参与，可能需要您提供姓名、性别、年龄、喜好、乐趣等个人信息。

      对于上述附加服务，您可以选择不提供这些个人信息，您依然可以正常使用其他功能。</p>

    <h4 className="font-semibold my-2.5">6. 我们从第三方获取您的个人信息</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">我们可能会从第三方间接获取您的个人信息，但这只发生在：我们确认该等第三方已经就将相关数据分享给我们获得您的授权同意，或者第三方依法被许可或被要求向我们披露您的个人信息。

      我们可能从第三方获取您的个人信息的情形主要包括：

      ·  由第三方平台向我们提供的参展商的员工、联系人或其他代表的个人信息，包括个人上网记录（浏览与使用记录等）；

      我们会要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应法律责任。

      我们会了解第三方已获得的个人信息处理的授权同意范围，包括使用目的，个人信息主体是否授权同意转让、共享、公开披露、删除等内容。

      这些从第三方获取的个人信息将用于为您提供服务，以及用于保证我们所掌握的您的相关记录的准确性。如果我们对这些个人信息的处理活动超出第三方已获得的授权同意范围，在获取个人信息的合理期限内或处理个人信息前，我们会直接或通过提供信息的第三方征得您的明示同意。

      为避免歧义，上述情形与我们所收集的个人信息的种类并非一一对应，在上述某一情形中所收集的个人信息，也可能在上述其他情形中被收集。</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">（二）我们对您的个人信息的使用</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">我们使用上述个人信息和其他类似的信息来为您参加有大的各类活动提供便利，并会为您提供外有大主办或承办的其他展览会的信息及服务。

      当我们要将个人信息用于本政策未载明的其它用途时，会事先征求您的同意。

      当我们要将基于特定目的收集的个人信息用于其他目的时，会事先征求您的同意。</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">（三）征得同意的例外</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">在法律允许的范围内，我们可以在以下情形中收集、使用您的个人信息，而无需征得您的授权同意，且我们可能不会响应您提出的更正/修改、删除、注销、撤回同意、索取信息的请求：

      1. 与国家安全、国防安全有关的；

      2. 与公共安全、公共卫生、重大公共利益有关的；

      3. 与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；

      4. 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；

      5. 您自行向社会公众公开的个人信息；

      6. 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；

      7. 根据与您签订和履行相关协议或其他书面文件所必需的；

      8. 用于维护所提供的产品及/或服务的安全稳定运行所必需的，例如发现、处置产品及/或服务的故障；

      9. 为合法的新闻报道所必需的；

      10. 学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；

      11. 法律法规规定的其他情形。</p>

    <h4 className="font-semibold my-2.5">二、 我们如何使用Cookie和同类技术</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">为您获得更好的访问体验、向您推荐您可能感兴趣的内容，我们将会使用Cookies及像素标签/信标和脚本等类似技术（以下统称为“Cookies”）。借助于Cookies，我们能够存储关于访问和访问者的信息，并通过分析数据为您提供更好更多的服务。如果您使用有大服务，即表示您同意接受我们使用Cookies为您提供服务。

      您可修改对Cookies的接受程度或拒绝我们的Cookies，但拒绝Cookies可能导致您在某些情况下无法使用依赖于Cookies的部分服务功能。

      关于我们使用的Cookies类型、为什么使用Cookies以及如何管理Cookies的更多信息，请参阅我们的《Cookies政策》。</p>

    <h4 className="font-semibold my-2.5">三、 我们如何委托处理、共享、转让、公开披露您的个人信息</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">（一）委托处理</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">我们的部分模块或功能可能由外部服务商提供，例如，我们可能会聘请专业数据处理服务机构来为我们的平台提供数据分析方面的技术支持。

      对我们委托处理个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的要求、本政策以及其他任何相关的保密和安全措施来处理个人信息。</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">（二）共享</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">您的个人信息将在本次活动的的境内外参展商和用户之间（包括参展商与参展商、参展商与用户之间）共享。您确认并明确同意我们通过该方式对您的信息进行内部共享。我们会对您的信息采取适当的技术措施以保障信息安全。

      此外，在下列情形中，我们将与第三方分享您的个人信息：

      1. 在征得您的同意后，我们将与外贸中心以外的其他方分享您的个人信息；

      2. 我们可能会根据法律法规、诉讼争议解决需要，或按行政、司法机关的强制性要求，对外共享您的个人信息；

      3. 我们将把您的个人信息与我们的关联公司共享。我们只会共享必要的个人信息，且受本政策中所声明目的的约束。如果我们共享您的个人敏感信息或关联公司改变个人信息的使用和处理目的，将再次征求您的同意；

      4. 我们可能会与本次活动的授权合作伙伴（如活动主办方、第三方平台）分享您的个人信息以保障我们的产品和/或服务的顺利供应与完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供我们的产品和/或服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他目的。

      对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理个人信息。</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">（三）转让</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：

      1. 获得您的明确同意后，我们会向其他方转让您的个人信息；

      2. 在涉及合并、收购、解散或破产清算时，如涉及到个人信息转让，我们将向您告知该等转让，并会要求新的持有您个人信息的公司、组织继续受此政策的约束，否则我们将要求该公司、组织重新向您征求同意。</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">（四）公开披露</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">我们仅会在以下情况下，公开披露您的个人信息：

      1. 获得您明确同意后；

      2. 基于法律的披露：在法律、法律程序、诉讼或有关政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</p>

    <h4 className="font-semibold my-2.5">四、 我们如何保护您的个人信息</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">我们高度重视您的个人信息安全。例如，我们使用加密、脱敏等技术提高个人信息的安全性；使用受信赖的保护机制防止个人信息遭到恶意攻击；部署访问控制机制，防止未授权的访问；举办安全和隐私保护培训课程。我们采取的措施旨在为您提供与处理您的个人信息产生的风险相匹配的安全保障级别。

      我们会采取合理可行的措施，尽力避免收集无关的个人信息。

      我们只会在达成本政策所述目的所需的期限内保留您的个人信息，但法律法规另有规定的除外。当所留存的个人信息超过保存期限、我们停止运营服务或在您注销账户后，我们会将您的个人信息删除或将其匿名化，如果不能采取这类措施（比如，因您的信息已存储在备份存档中），则我们将安全地存储您的个人信息并在能够删除此类信息前避免对其做进一步的处理。

      尽管没有百分之百安全的网络传播或电子存储方法，这使我们不能保证个人信息的绝对安全，但我们将采取适当的技术与组织保障措施，以保护您的信息不被盗取、丢失、滥用以及任何未经授权的访问、复制、收集、使用、披露、更改或者损毁。</p>

    <h4 className="font-semibold my-2.5">五、 您的权利</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">（一）访问您的个人信息</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">除法律法规另有规定外，您有权随时登录您的账户，自行访问您的个人信息。

      您也可以通过“八、如何联系我们”中所提供的联系方式来联系我们，以行使这项权利。</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">（二）更正您的个人信息</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">当您发现我们处理的您的个人信息有错误时，您有权要求我们做出更正。您可以自行登录您的账户，对您的个人信息进行修改更正。

      您也可以通过“八、如何联系我们”中所提供的联系方式来联系我们，以行使这项权利。</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">（三）删除您的个人信息</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">在以下情形中，您可以通过“八、如何联系我们”中所提供的联系方式向我们提出删除个人信息的请求：

      1. 如果我们处理个人信息的行为违反法律法规；

      2. 如果我们收集、使用您的个人信息，却未征得您的同意；

      3. 如果我们处理个人信息的行为违反了与您的约定；

      4. 如果您不再使用我们的产品或服务，或您注销了账号；

      5. 如果我们不再为您提供产品或服务。

      若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。

      当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息。</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">（四）改变您授权同意的范围或撤回您的授权</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">在您授权我们收集和处理您的个人信息的范围内，您可以通过更改设置等方式改变您授权我们处理您信息的范围或撤回您的授权。

      例如，我们会运用算法向您的智能终端设备推送展览资讯及服务信息，但您随时有权选择退订。您可以通过更改您的智能终端设备中的设置来进行退订。您也可以通过“八、如何联系我们”中所提供的联系方式来联系我们，以行使这项权利。

      请您理解，每个业务功能需要一些必要的信息才能得以完成，当您撤回同意或授权后，我们将无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的信息处理。</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">（五）注销您的账户</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">您可以自行登录您的账户，并对其进行注销。

      您也可以通过“八、如何联系我们”中所提供的联系方式来联系我们，以行使这项权利。</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">（六）获取个人信息副本</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">您可以自行登录您的账户，获取您的个人信息副本。

      您也可以通过“八、如何联系我们”中所提供的联系方式来联系我们，以行使这项权利。</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">（七）约束信息系统自动决策</p>
    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害外贸中心商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">（八）响应您的上述请求</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。

      我们将在三十（30）天内答复您的请求。如您不满意或有任何问题，您可以通过“八、如何联系我们”中所提供的联系方式来联系我们。

      对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。

      在以下情形中，按照法律法规要求，我们将无法响应您的请求：

      1. 与国家安全、国防安全直接相关的；

      2. 与公共安全、公共卫生、重大公共利益直接相关的；

      3. 与犯罪侦查、起诉、审判和判决执行等直接相关的；

      4. 有充分证据表明您存在主观恶意或滥用权利的；

      5. 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；

      6. 涉及商业秘密的。</p>

    <h4 className="font-semibold my-2.5">六、 我们如何处理儿童的个人信息</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">我们的平台、产品与/或服务主要面向成人。如果没有父母或监护人的同意，儿童不得创建自己的用户账户。

      我们不会主动直接向儿童收集其个人信息。对于经监护人同意而收集儿童个人信息的情况，我们只会在受到法律允许、监护人同意或者保护儿童所必要的情况下使用或公开披露此信息。

      我们将不满14周岁的任何人均视为儿童。

      如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。</p>

    <h4 className="font-semibold my-2.5">七、 本政策如何更新</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">我们可能适时地更新本政策以适应法律、技术或商业的发展。但未经您明确同意，我们不会削减您依据本政策所应享有的权利。我们会在本页面上发布最新版本的隐私政策，您可以在本政策的顶部的“最新更新日期”处查看本政策的最新更新时间。

      如果我们对本政策进行重大变更，我们将以适当的方式通知您。若您在本政策变更后继续使用我们的服务，则表示您已充分阅读、理解并愿意受修订后的隐私政策约束。此类隐私政策变化将从推送通知中规定的生效日期开始适用。如您不同意更新后的隐私政策，您应停止访问或使用我们的平台。

      本政策所指的重大变更包括但不限于：

      1. 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；

      2. 我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；

      3. 个人信息共享、转让或公开披露的主要对象发生变化；

      4. 您参与个人信息处理方面的权利及其行使方式发生重大变化；

      5. 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；

      6. 个人信息安全影响评估报告表明存在高风险时。</p>

    <h4 className="font-semibold my-2.5">八、 如何联系我们</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">您可以通过以下方式联系我们：

      邮箱：info@gotin.online

      我们将尽快审核所涉问题，并在验证您的用户身份后的三十（30）天内予以回复。

      如果我们的回复不能使您满意，或您认为我们的个人信息处理方式损害了您的合法权益，您可以与我们协商解决，协商不成的，您可以并同意将相关争议提交我们所在地有管辖权的人民法院适用中华人民共和国法律（不含香港、澳门和台湾地区）法律解决。</p>

    <h4 className="font-semibold my-2.5">Cookies政策</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">最近更新日期：2020-08-31

      《Cookies政策》应与我们的《隐私政策》一起阅读，《隐私政策》解释了我们如何收集、处理和保护您的个人信息。

      Cookie政策适用于<span className='text-gotinc'><Link to="/" >https://www.gotin.online</Link></span>电脑版和手机版及相关子域名、APP、有大主办及承办的各类活动的平台等。

      如果您对本《Cookies政策》或我们使用cookies有任何疑问，请通过“联系我们”中提供的途径与我们联系。</p>

    <h4 className="font-semibold my-2.5">什么是Cookies？</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">Cookies是从您正在访问的网站下载到您设备上的小型数据文件。您下次访问同一网站时，cookies可确保能够识别您的设备。通过使用cookies及像素标签/信标和脚本等类似技术（以下统称为“Cookies”），该网站可以保存关于访问和访问者的信息。</p>

    <h4 className="font-semibold my-2.5">Cookies的使用</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">我们可能使用Cookies向您推送有大官方平台及有大各类活动最新展览讯息。

      我们在有大官方平台及各类活动的平台上放置了一些Cookies，但从有大官方平台及各类活动的平台跳转至第三方网站、应用和／或服务（以下简称“第三方服务”）时，第三方服务的Cookies由该第三方放置及管理，并可能在有大官方平台及有大各类活动收集数据。

      请注意，如果您使用第三方服务时向第三方提供您的个人信息，您的信息应当适用该第三方服务的隐私政策或类似的Cookies政策。我们对任何第三方不当使用或披露由您提供的信息不承担任何法律责任。

      有大官方平台及有大各类活动的平台使用的Cookies

      有大官方平台及有大各类活动的平台使用以下类型的Cookies：</p>

    <h4 className="font-semibold my-2.5">(1)分析Cookies</h4>

    分析Cookies帮助我们改善有大官方平台及有大各类活动平台。这些Cookies 收集有关访问者使用有大官方平台及有大各类活动的平台的信息，包括用户来源地、登陆与访问网页的时长、访问最多的网页、及无法正常显示信息的网页等相关情况。网页分析Cookies是此类Cookies 中的一种。

    <h4 className="font-semibold my-2.5">(2)营销和其他Cookies</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">营销Cookies通常由我们和广告商之间的中介公司放置在有大官方平台及有大各类活动的平台上。这些Cookies用于：

      · 根据您对有大官方平台及有大各类活动的访问和点击行为，通过各种媒介向您显示相关的个性化广告或优惠（例如，电子邮件、社交媒体和横幅广告）；

      · 限制显示每条广告的次数；

      · 衡量广告活动的有效性；

      · 设置社交媒体链接，这样当您想通过有大官方平台及有大各类活动使用社交媒体时能够识别您的身份。</p>

    <h4 className="font-semibold my-2.5">Cookies的管理</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">如果您使用有大服务，即表示您同意在有大官方平台及有大各类活动上放置并读取Cookies。

      如果您不希望我们在您的设备上放置Cookies，您可以通过浏览器的设置或选项拒绝使用Cookies。

      请注意，如果您拒绝我们在您的设备上放置Cookies，您之后可能无法正常使用有大官方平台及有大各类活动的某些功能。

      以下链接提供了在常用网络浏览器软件中阻止或接受Cookies的说明：</p>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">· <a className="text-gotinc" href="https://support.google.com/chrome/answer/95647" rel="noreferrer">Chrome</a>
      · <a className="text-gotinc" href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer" rel="noreferrer">Firefox</a>
      · <a className="text-gotinc" href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11" rel="noreferrer">Internet Explorer</a>
      · <a className="text-gotinc" href="https://support.apple.com/kb/PH21411?locale=en_US&viewlocale=en_US">Safari</a></p>

    <h4 className="font-semibold my-2.5">Cookies政策的变更</h4>

    <p className="whitespace-pre-wrap text-gotin py-2.5 px-1">如果本Cookies政策或放置的Cookies变更，我们将调整Cookies政策和放置的Cookies列表。

      若您继续浏览我们的网站，即表示您同意接受修订后的Cookies政策的约束及变更后的Cookies对数据的收集。

      联系我们

      邮箱：info@gotin.online</p>

  </div>
}

export default Privacy